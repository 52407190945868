<template>
  <div :id="id" :style="{ width: width, height: height }" />
</template>
<script>
import * as echarts from 'echarts'
import china from '../utils/china.json'
import city from '../utils/city'
let oMap = null
export default {
  name: '',
  props: {
    id: {
      type: String,
      require: true,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    /* upDate用于判断是否需要更新全部图形,
     * true  为更新全部图形（重新加载图形）,
     * false 为追加数据，只更新部分数据图形
     */
    upDate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mapData: []
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.upDate) {
          this.initCharts()
        }
      }
    }
  },
  mounted() {
    this.initCharts()
  },
  methods: {
    initCharts() {
      echarts.registerMap('china', { geoJSON: china })
      const chinaGeoCoordMap = {
        崇信: [107.05, 35.27]
      }
      const chinaDatas = [
        [
          {
            name: '崇信',
            value: ''
          }
        ]
      ]
      this.options.forEach((e) => {
        for (const item in city) {
          if (e.placeReceiptName.substring(0, 2) === item.substring(0, 2)) {
            chinaGeoCoordMap[e.placeReceiptName] = city[item]
            chinaDatas.push([
              {
                name: e.placeReceiptName,
                value: e.shipmentsNum
              }
            ])
          }
        }
      })
      const convertData = function (data) {
        const res = []
        for (let i = 0; i < data.length; i++) {
          const dataItem = data[i]
          const toCoord = chinaGeoCoordMap[dataItem[0].name]
          const fromCoord = [107.05, 35.27]
          if (fromCoord && toCoord) {
            res.push([
              {
                coord: fromCoord,
                name: dataItem[0].name,
                num: dataItem[0].value
              },
              {
                coord: toCoord
              }
            ])
          }
        }
        return res
      }
      // var planePath =
      //   'path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705'

      // var color = ['#FF8888', ' #ffa022', '#a6c84c', '#3ed4ff', '	#E38EFF']
      const series = [];
      [['崇信', chinaDatas]].forEach(function (item, i) {
        series.push(
          {
            name: item[0],
            type: 'lines',
            zlevel: 1,
            symbol: ['none', 'arrow'],
            symbolSize: 0,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'arrow', //箭头图标
              color: '#21F3BF',
              symbolSize: 2 //图标大小
            },
            lineStyle: {
              normal: {
                color: '#21F3BF',
                width: 0, //尾迹线条宽度
                opacity: 0.2, //尾迹线条透明度
                curveness: 0.3 //尾迹线条曲直度
              }
            },
            data: convertData(item[1])
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              //涟漪特效
              period: 3, //动画时间，值越小速度越快
              brushType: 'stroke', //波纹绘制方式 stroke, fill
              scale: 3 //波纹圆环最大限制，值越大波纹越大
            },
            label: {
              normal: {
                show: true,
                position: 'right', //显示位置
                offset: [0, 0], //偏移设置
                formatter: function (params) {
                  //圆环显示文字
                  return params.name === '崇信' ? params.name : ''
                },
                fontSize: 10
              },
              emphasis: {
                show: false
              }
            },
            // symbol: 'circle',
            symbolSize: function (val) {
              if (val[0] === 107.05 && val[1] === 35.27) {
                return 10
              } else {
                return 5 //圆环大小
                // return val[2] / 6
              }
            },
            itemStyle: {
              normal: {
                show: true,
                color: function (params) {
                  //圆环显示文字
                  return params.name === '崇信' ? '#ec1615' : '#3ed4ff'
                }
              }
            },
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[0].name,
                value: chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value]),
                num: dataItem[0].value
              }
            })
          }
        )
      })

      const option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(62, 212, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function (params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            var value = params.data.num
            if (name === '崇信') {
              res = "<span style='color:#fff;'>" + name + '</span>'
            } else {
              res = "<span style='color:#fff;'>" + name + '<br/>数量：' + value + '</span>'
            }
            return res
          }
        },
        geo: {
          map: 'china',
          zoom: 1.5,
          top: 120,
          bottom: 'auto',
          scaleLimit: {
            //控制滚轮缩放大小
            max: 15,
            min: 0.5
          },
          label: {
            emphasis: {
              show: false
            }
          },
          roam: true, //是否允许缩放
          itemStyle: {
            normal: {
              color: '#132937', //地图背景色
              borderColor: '#0692a4', //省市边界线00fcff 516a89
              borderWidth: 1
            },
            emphasis: {
              color: '#0b1c2d' //悬浮背景
            }
          }
        },
        series: series
      }
      oMap = echarts.init(document.getElementById(this.id))
      oMap.clear()
      oMap.setOption(option)
      window.addEventListener("resize", function() {
        oMap.resize()
      })
      oMap.off()
    }
  },

  beforeDestroy () {
    oMap.clear()
    oMap.dispose()
  }
}
</script>
