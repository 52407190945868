import request from '@/utils/request'
import { download } from '@/utils/index'
// /out/logistics/listLogisticsInfoOut
// 上行物流信息流出
export function listLogisticsInfoOut(query) {
  return request({
    url: '/out/logistics/listLogisticsInfoOut',
    method: 'get',
    params: query
  })
}
// /out/logistics/listCulturalSalesStatistics
// 文创营销统计
export function listCulturalSalesStatistics(query) {
  return request({
    url: '/out/logistics/listCulturalSalesStatistics',
    method: 'get',
    params: query
  })
}
// /out/logistics/listLogisticsShipmentAnalysisTotal
// 物流寄件分析
export function listLogisticsShipmentAnalysisTotal(query) {
  return request({
    url: '/out/logistics/listLogisticsShipmentAnalysisTotal',
    method: 'get',
    params: query
  })
}
// /out/logistics/listStatisticsProductRanking
// 网购产品排行
export function listStatisticsProductRanking(query) {
  return request({
    url: '/out/logistics/listStatisticsProductRanking',
    method: 'get',
    params: query
  })
}
// /out/logistics/listStatisticsTotal
// 网购统计总数
export function listStatisticsTotal(query) {
  return request({
    url: '/out/logistics/listStatisticsTotal',
    method: 'get',
    params: query
  })
}
// /out/logistics/listStatisticsTrend
// 网购统计趋势图
export function listStatisticsTrend(query) {
  return request({
    url: '/out/logistics/listStatisticsTrend',
    method: 'get',
    params: query
  })
}
// /out/logistics/listTotalTourismMarketingOrSaleTrend
// 旅游票数和销售数趋势
export function listTotalTourismMarketingOrSaleTrend(query) {
  return request({
    url: '/out/logistics/listTotalTourismMarketingOrSaleTrend',
    method: 'get',
    params: query
  })
}
// /out/logistics/listTourismMarketingTotal
// 旅游营销总数
export function listTourismMarketingTotal(query) {
  return request({
    url: '/out/logistics/listTourismMarketingTotal',
    method: 'get',
    params: query
  })
}

