<template>
  <div class="serviceStation flex pt20px pd1h">
    <div class="">
      <div class="part">
        <itemBg width="29" height="19" title="网购运营" />
        <div class="part_con">
          <div class="mb1h mt1h">
            <div class="vl_text">总营业额：</div>
            <CountFlop v-if="turnoverTotal" :val="turnoverTotal | numrmb" suffix="万元" />
          </div>
          <div>
            <div class="vl_text">总销量：</div>
            <CountFlop v-if="saleNumTotalT" :val="saleNumTotalT | num" />
          </div>
        </div>
      </div>
      <div class="part  mt2h">
        <itemBg width="29" height="32" title="网购销售额/销售趋势" />
        <div class="part_con">
          <e-charts id="listStatisticsTrend" width="29vw" height="32vh" :options="rbt" />
        </div>
      </div>
      <div class="part  mt2h">
        <itemBg width="29" height="35" title="网购销售产品TOP10" />
        <div class="part_con">
          <ul class="title">
            <li>单位名称</li><li>产品名称</li><li>销售额(万元)</li><li>销售量(件)</li>
          </ul>
        <div class="scol">
          <div>
<!--            <div :class="{animationTop}" @mouseover="mouseOverList" @mouseleave="mouseLeaveList">-->
                        <vue-seamless-scroll :class-option="classOption" :data="topList" class="warp">
              <ul class="item"  v-for="(item, index) in topList" :key="index">
                <li  v-tooltip.top="item.unitName">{{item.ranking}}. {{item.unitName}}</li>
                <li v-tooltip.top="item.saleProduct">{{item.saleProduct}}</li>
                <li>{{item.turnoverTotal}}</li>
                <li>{{item.saleNumTotal}}</li>
              </ul>
            </vue-seamless-scroll>
          </div>

        </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="40" height="53" title="商品流向" />
          <div class="part_con">
            <InflowMapT id="map" width="40vw" height="53vh" :options="mapData" />
          </div>
        </div>
      <div class="part mt2h">
        <itemBg width="40" height="35" title="物流（寄件）分析" />
        <div class="part_con">
          <e-charts id="listLogisticsShipmentAnalysisTotal" width="24.5vw" height="35vh" :options="lt" />
          <div class="logistics">
            <div>
              <h3>当月物流量</h3>
<!--              <span>{{monthNumLogistics | num}}</span>-->
              <span><countTo :separator= separator :startVal='startVal' :endVal='monthNumLogistics' :duration='duration' /><i>件</i></span>
            </div>
            <div>
              <h3>总物流量</h3>
<!--              <span>{{totalNumLogistics | num}}</span>-->
              <span><countTo :separator= separator :startVal='startVal' :endVal='totalNumLogistics' :duration='duration' /><i>件</i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="29" height="19" title="旅游营销" />
        <div class="part_con">
          <div class="mb1h mt1h">
            <div class="vl_text">总销售额：</div>
<!--            <countTo class="count" :decimals="2" :separator= separator :startVal='startVal' :endVal='totalPrice' :duration='duration' />-->
            <CountFlop v-if="totalPrice" :val="totalPrice | numrmb" suffix="元" />
          </div>
          <div>
            <div class="vl_text">总票数：</div>
<!--            <countTo class="count" :separator= separator :startVal='startVal' :endVal='saleNumTotal' :duration='duration'  />-->
            <CountFlop v-if="saleNumTotal" :val="saleNumTotal | num" />
          </div>
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="29" height="32" title="旅游票数/销售额趋势 " />
        <div class="part_con">
          <e-charts id="sevenDayTownOrder" width="29vw" height="32vh" :options="rb" />
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="29" height="35" title="文创营销" />
        <div class="part_con" style="padding-top: 0;">
          <div class="culture" style="width:29vw;height:16vh">
            <div>
              <h3>销售总量</h3>
<!--              <span>{{culturalNumTotalCulture | num}}</span>-->
              <span><countTo :separator= separator :startVal='startVal' :endVal='culturalNumTotalCulture' :duration='duration' /><i>个</i></span>
            </div>
            <div>
              <h3>销售总额</h3>
<!--              <span>{{totalAmountCulture | num}}</span>-->
              <span><countTo :separator= separator :startVal='startVal' :endVal='totalAmountCulture' :duration='duration' /><i>元</i></span>
            </div>
          </div>
          <e-charts id="all" width="29vw" height="18vh" :options="rt" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BAR_OPTINO, PIE_OPTINO, LINE_OPTINO } from '@/utils/eChartsSetOption'
import routeChange from '@/mixins/routeChange'
import InflowMapT from '@/components/InflowMapT'
import itemBg from '@/components/itemBg'
import countTo from 'vue-count-to'
import CountFlop from '@/components/countFlop.vue'
import vueSeamlessScroll from 'vue-seamless-scroll'
import {
  listLogisticsInfoOut,
  listCulturalSalesStatistics,
  listLogisticsShipmentAnalysisTotal,
  listStatisticsProductRanking,
  listStatisticsTotal,
  listStatisticsTrend,
  listTotalTourismMarketingOrSaleTrend,
  listTourismMarketingTotal
} from '@/api/viewData'
export default {
  name: '',
  components: { itemBg, InflowMapT, countTo, vueSeamlessScroll, CountFlop },
  mixins: [routeChange],
  data() {
    return {
      lt: JSON.parse(JSON.stringify(BAR_OPTINO)),
      rt: JSON.parse(JSON.stringify(PIE_OPTINO)),
      rb: JSON.parse(JSON.stringify(LINE_OPTINO)),
      rbt: JSON.parse(JSON.stringify(LINE_OPTINO)),
      mapData: [],
      // 需要滚动的时间
      duration: 3000,
      // 初始值
      startVal: 0,
      // 最终值
      totalPrice: 0,
      saleNumTotal: 0,
      turnoverTotal: 0,
      saleNumTotalT: 0,
      separator: ',',
      topList: [],
      totalNumLogistics: 0,
      monthNumLogistics: 0,
      culturalNumTotalCulture: 0,
      totalAmountCulture: 0,
      animationTop: false,
      timer: null,
      timer2: null,
      tipName: '',
      isShowTipName: false
    }
  },
  filters: {
    num: (val) => {
      val = '' + val // 转换成字符串
      let int = val
      int = int.split('').reverse().join('') // 翻转整数
      let temp = '' // 临时变量
      for (let i = 0; i < int.length; i++) {
        temp += int[i]
        if ((i + 1) % 3 === 0 && i !== int.length - 1) {
          temp += ',' // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp.split('').reverse().join('') // 加完逗号之后翻转
      return temp // 返回
    },
    numrmb: (value) => {
      if (!value) return '0.00'
      value = value.toFixed(2)
      const intPart = Math.trunc(value)// 获取整数部分
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
      let floatPart = '' // 预定义小数部分
      const value2Array = value.split('.')
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString() // 拿到小数部分
        if (floatPart.length === 1) { // 补0,实际上用不着
          return intPartFormat + '.' + floatPart
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    }
  },
  computed: {
    classOption() {
      return {
        step: 0.5, //数值越大速度滚动越快
        limitMoveNum: 10, //开始无缝滚动的数据量  //this.fourDatata.length
        hoverStop: true, //是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, //开启数据实时监控刷新dom
        singleHeight: 0, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    showScroll() {
      this.animationTop = true
      window.clearInterval(this.timer)
      this.timer = null
      this.timer = setInterval(this.showScroll, 300)
      this.timer2 = setTimeout(() => {
        this.topList.push(this.topList[0], this.topList[1])
        this.topList.shift()
        this.topList.shift()
        this.animationTop = false
        clearTimeout(this.timer2)
        this.timer2 = null
      }, 30)
    },
    mouseOverList(tip) {
      this.tipName = tip
      this.isShowTipName = true
      clearInterval(this.timer)
    },
    mouseLeaveList() {
      this.timer = setInterval(this.showScroll, 300)
    }
  },
  mounted() {
    //TOP10滚动动画
    // this.timer = setInterval(this.showScroll, 300)
    //商品流向
    listLogisticsInfoOut().then((res) => {
      this.mapData = res
    })
    //文创营销统计
    listCulturalSalesStatistics().then((res) => {
      const culturalSalesNumVoList = res.culturalSalesNumVoList.map(v => {
        return { name: v.culturalName, value: v.culturalNum }
      })
      this.rt.series[0].data = culturalSalesNumVoList
      this.rt.series[0].radius = '80%'
      this.rt.series[0].center = ['25%', '50%']
      this.rt.legend.right = 50
      this.rt.legend.top = 50
      //销售总量
      this.culturalNumTotalCulture = res.culturalNumTotal
      //销售总额
      this.totalAmountCulture = res.totalAmount
    })
    //物流分析
    listLogisticsShipmentAnalysisTotal().then((res) => {
      this.totalNumLogistics = res.totalNum
      this.monthNumLogistics = res.monthNum
      this.lt.xAxis.data = res.monthList
      this.lt.series[0].data = res.numTotalList
      this.lt.grid.left = 40
      this.lt.series[0].barWidth = '35%'
      this.lt.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
      this.lt.xAxis.axisLabel.textStyle.color = '#fff'
    })
    //TOP10
    listStatisticsProductRanking().then((res) => {
      this.topList = res
    })
    //网购运营
    listStatisticsTotal().then((res) => {
      this.saleNumTotalT = res.saleNumTotal
      this.turnoverTotal = res.turnoverTotal
    })
    //网购销售额/销售趋势
    listStatisticsTrend().then((res) => {
      const data = {}
      data['销售额(万元)'] = res.turnoverTotalList
      data['销量(件)'] = res.saleNumTotalList
      this.rbt.xAxis.data = res.monthList
      this.rbt.xAxis.name = '月份'
      this.rbt.legend.data = Object.keys(data)
      Object.entries(data).forEach((e) => {
        this.rbt.series.push({
          name: e[0],
          data: e[1],
          type: 'line',
          smooth: false
        })
      })
      this.rbt.grid.left = 60
      this.rbt.grid.right = 50
      this.rbt.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
      this.rbt.xAxis.axisLabel.textStyle.color = '#fff'
    })
    // 旅游票数/销售额趋势
    listTotalTourismMarketingOrSaleTrend().then((res) => {
      const data = {}
      data['销售额(元)'] = res.totalPriceList
      data['票数(张)'] = res.saleNumList
      this.rb.xAxis.data = res.monthList
      this.rb.xAxis.name = '月份'
      this.rb.legend.data = Object.keys(data)
      this.rb.tooltip.confine = true
      Object.entries(data).forEach((e) => {
        this.rb.series.push({
          name: e[0],
          data: e[1],
          type: 'line',
          smooth: false
        })
      })
      this.rb.grid.left = 60
      this.rb.grid.right = 50
      this.rb.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
      this.rb.xAxis.axisLabel.textStyle.color = '#fff'
    })
    //网络运营
    listTourismMarketingTotal().then((res) => {
      this.totalPrice = res.totalPrice
      this.saleNumTotal = res.saleNumTotal
    })
  },
  watch: {
    topList: {
      deep: true,
      handler() {
        return this.topList
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .vue-tooltip .tooltip-custom {
    color:#fff;
    background-color: #546E7A;
    box-shadow: 0 5px 30px rgba(0 , 0 , 0 , .6);
    padding : 12px 55px;
  }
  .vue-tooltip .tooltip-custom .tooltip-arrow {
    color:#fff;
    border-color: #546E7A;
  }
  .animationTop{
    transition: all .1s linear;
    margin-top: -60px;
  }
  .logistics{
    padding-top: 40px;
    display: inline-block;
    padding-top: 5vh;
    padding-left: 1.5vw;
    width: 11.5vw;
    div{
      text-align: center;
      border-bottom: 1px dashed #1C65FE;
      +div{
        margin-top: 3vh;
      }
      h3{
        font-size: 2.4vh;
        color: #ffffff;
        line-height: 2.4vh;
        margin: 0;
        padding: 0 0 8px;
        /*border-bottom: 1px dashed #1C65FE;*/
      }
      span{
        font-size: 5vh;
        color: #00EAFF;
        /*line-height: 6vh;*/
        i{
          font-style: normal;
          font-size: 13px;
        }
      }
    }
  }
  .culture{
    display: flex;
    justify-content: space-between;
    padding: 6vh 1vw 0;
    box-sizing: border-box;
    div{
      display: inline-block;
      width: 11.5vw;
      text-align: center;
      border-bottom: 1px dashed #1C65FE;
      height: 9vh;
     h3{
       font-size: 2.4vh;
       color: #ffffff;
       line-height: 2.4vh;
       margin: 0;
       padding: 0;
     }
      span{
        font-size: 5vh;
        color: #00EAFF;
        /*line-height: 6vh;*/
        i{
          font-style: normal;
          font-size: 13px;
        }
      }
    }
  }
  #listLogisticsShipmentAnalysisTotal{
    position: absolute;
    right: 1vw;
  }
  .vl_text{
    width: 22%;
    text-align: right;
    color: #ffffff;
    font-size: 2vh;
    line-height: 5.5vh;
    display: inline-block;
    vertical-align: bottom
  }
  .vl_text + span{
    width: 80%;
  }
  .part .part_con{
    width: 100%;
  }
  .count{
    color: #00eaff;
    font-size: 5vh;
    letter-spacing: 6px;
    margin-left: -2vh;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }
  .title{
    display: flex;
    justify-content: left;
    padding: 20px 15px 10px;
    li{
      font-size: 16px;
    }
    li:nth-child(1){
      width: 18vw;
      margin-right: 10px;
    }
    li:nth-child(2){
      width: 8vw;
      /*margin-right: 10px;*/
    }
    li:nth-child(3){
      width: 8vw;
      text-align: right;
      margin-right: 10px;
    }
    li:nth-child(4){
      width: 9vw;
      text-align: right;
    }
  }
  .item{
    display: flex;
    justify-content: left;
  }
  .item:nth-child(odd){
    background: #00479D;
  }
  .item{
    padding: 0 15px 0;
    li{
      height: 30px;
      line-height: 30px;
      overflow: hidden;/*超出部分隐藏*/
      white-space: nowrap;/*不换行*/
      text-overflow:ellipsis;/*超出部分文字以...显示*/
      font-size: 16px;
      color: #57C7ED;
      position: relative;
      i{
        display: inline-block;
        position: absolute;
        z-index: 100;
      }
    }
    li:nth-child(1){
      width: 18vw;
      margin-right: 10px;
    }
    li:nth-child(2){
      width: 8vw;
      /*margin-right: 10px;*/
    }
    li:nth-child(3){
      width: 8vw;
      text-align: right;
      margin-right: 10px;
    }
    li:nth-child(4){
      width: 9vw;
      text-align: right;
    }
  }
  .scol{
    overflow: hidden;
    height: 25vh;
  }
  .scol::-webkit-scrollbar {
    width: 4px;
  }
  .scol::-webkit-scrollbar-track {
    background-color:transparent;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    border-radius:1em;
  }
  .scol::-webkit-scrollbar-thumb {
    background-color:#00A2FF;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    border-radius:1em;
  }
</style>
